import { useForm, Controller } from 'react-hook-form'

import { useStore } from 'effector-react'

import {
  ActionPopupWrapper,
  SelectMultiple,
  WarningOutlined,
  IconButton,
  RoundedCross,
  LongText,
} from '@gmini/ui-kit'

import { useEffect } from 'react'

import { FieldError } from '@gmini/components'

import * as smApi from '@gmini/sm-api-sdk'

import {
  FieldContainer,
  Form,
  ProjectContainer,
  TemplatesContainer,
  CopyIssueTemplateContainerWrapper,
  WarningContainer,
  WarningText,
  ProjectFieldLabel,
  SimpleScrollbar,
  TemplatesContainerHeaderTitle,
  TemplatesContainerHeaderCount,
  TemplatesContainerHeader,
  TemplatesItem,
  TemplatesItems,
} from './CopyIssueTemplatePopup.styled'

import { openPopup$, toggleOpenPopup } from './сopyIssueTemplatePopup.store'
import { FormValueCopyIssueTemplate } from './CopyIssueTemplatePopup.types'

type CopyIssueTemplatePopupProps = {
  templates: { id: number; name: string }[]
  onSubmit: (data: FormValueCopyIssueTemplate) => void
  title: string
  submitButtonTitle: string
  onClose?: () => void
  projectList: smApi.Project[]
}

export const CopyIssueTemplatePopup = ({
  templates,
  onSubmit,
  title,
  submitButtonTitle,
  onClose,
  projectList,
}: CopyIssueTemplatePopupProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValueCopyIssueTemplate>({
    mode: 'onChange',
    defaultValues: {
      templates,
      projects: [],
    },
  })

  const fieldTemplates = watch('templates', [])
  const fieldProjects = watch('projects', [])

  useEffect(() => {
    reset({
      projects: [],
      templates,
    })
  }, [templates, reset])

  const open = useStore(openPopup$)

  const onCloseHandler = () => {
    toggleOpenPopup(false)
    onClose?.()
    reset()
  }

  const onSubmitHandler = (data: FormValueCopyIssueTemplate) => {
    onSubmit(data)

    onCloseHandler()
  }

  return (
    <ActionPopupWrapper
      open={open}
      onClose={onCloseHandler}
      onSubmit={handleSubmit(onSubmitHandler)}
      width='1002px'
      submitButtonTitle={submitButtonTitle}
      title={title}
      dataTestIdFor={{
        declineButton: 'copyIssueTemplatePopupDeclineBtn',
        acceptButton: 'copyIssueTemplatePopupCreateBtn',
      }}
      pending={
        !(
          fieldProjects.length > 0 &&
          fieldTemplates.length > 0 &&
          fieldProjects.length <= 5
        )
      }
      footerStyle={{
        padding: '0px 32px 32px 32px',
      }}
    >
      {() => (
        <Form>
          <CopyIssueTemplateContainerWrapper>
            <TemplatesContainer>
              <TemplatesContainerHeader>
                <TemplatesContainerHeaderTitle>
                  Шаблон(ы)
                </TemplatesContainerHeaderTitle>
                <TemplatesContainerHeaderCount>
                  {fieldTemplates.length}
                </TemplatesContainerHeaderCount>
              </TemplatesContainerHeader>
              <SimpleScrollbar>
                <TemplatesItems>
                  <Controller
                    name='templates'
                    control={control}
                    render={({ field }) => (
                      <>
                        {field.value.map(key => (
                          <TemplatesItem key={key.id}>
                            <LongText withoutRightText partSize={20}>
                              {key.name}
                            </LongText>
                            <IconButton
                              onClick={() =>
                                field.onChange(
                                  field.value.filter(value => value !== key),
                                )
                              }
                            >
                              <RoundedCross />
                            </IconButton>
                          </TemplatesItem>
                        ))}
                      </>
                    )}
                  />
                </TemplatesItems>
              </SimpleScrollbar>
            </TemplatesContainer>
            {templates.length > 0 && (
              <ProjectContainer>
                <FieldContainer>
                  <ProjectFieldLabel required>
                    Выберите проект
                  </ProjectFieldLabel>
                  <Controller
                    name='projects'
                    control={control}
                    rules={{
                      required: { message: requiredErrorMessage, value: true },
                    }}
                    render={({ field }) => (
                      <SelectMultiple
                        {...field}
                        getOptionLabel={(option: smApi.Project) => option.name}
                        options={projectList}
                        placeholder='Выберите проект'
                        error={Boolean(errors.projects)}
                        colorScheme='white'
                      />
                    )}
                  />
                  <FieldError hidden={!errors.projects}>
                    {errors.projects && 'message' in errors.projects
                      ? errors.projects.message
                      : null}
                  </FieldError>
                </FieldContainer>
                <WarningContainer>
                  <WarningOutlined color='rgba(76, 94, 207, 1)' />
                  <WarningText>{warningText}</WarningText>
                </WarningContainer>
              </ProjectContainer>
            )}
          </CopyIssueTemplateContainerWrapper>
        </Form>
      )}
    </ActionPopupWrapper>
  )
}

const warningText =
  'Одновременно можно скопировать не более 50 шаблонов в 5 проектов'
const requiredErrorMessage = 'Это поле является обязательным'
